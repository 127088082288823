import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { App as AntApp, ConfigProvider } from "antd"
import { createContext, FC, memo, PropsWithChildren, Suspense, useContext, useEffect } from "react"
import introspection, { useMembersQuery, useMeQuery, useOrganizationQuery } from "src/graphql"
import { useBreakpoints } from "src/hooks/useBreakpoints"
import Router from "src/pages"
import { version } from "../../package.json"
import { Loader } from "../components/ui/Loader"
import { ContactContextProvider } from "./context/ContactContext"
import { InvoiceContextProvider } from "./context/InvoiceContext"
import { JobsContextProvider } from "./context/JobsContext"
import { mobileTheme, theme } from "./themes"

import "./index.less"
import { NotificationProvider } from "./context/NotificationContext"

type ContextProps = {
  app: { version: string }
  user: { authenticated: boolean | null; role: string | null; attributes: UsersPermissionsUser | null }
  company: CompanyFragment | null
  members: UsersPermissionsUserEntity[] | [] | null
}

const app: ContextProps["app"] = { version }

const defaultValue: ContextProps = {
  app: app,
  user: { authenticated: null, role: null, attributes: null },
  company: null,
  members: [],
}

const Context = createContext({ ...defaultValue })

const ContextProvider: FC<PropsWithChildren<ContextProps>> = ({ children, ...props }) => {
  const { data, error, loading } = useMeQuery()
  const { data: company, loading: organizationLoading } = useOrganizationQuery()
  const { data: members } = useMembersQuery()

  useEffect(() => {
    let isUnauthorized
    if (error?.networkError instanceof Object && "response" in error.networkError) {
      isUnauthorized = error?.networkError?.response?.status === 401
    }

    if (isUnauthorized && !loading) {
      localStorage.removeItem("jwt")
      location.reload()
    }
  }, [error, loading])

  if (loading || organizationLoading) return <Loader spinning size={"large"} />

  return (
    <Context.Provider
      value={{
        ...defaultValue,
        ...props,
        company: (company?.organization as CompanyFragment) ?? null,
        members: (members?.members?.data as UsersPermissionsUserEntity[]) ?? [],
        user: {
          authenticated: !!data?.me?.id,
          role: data?.me?.position ?? data?.me?.role?.data?.attributes?.name?.toUpperCase() ?? null,
          attributes: (data?.me as UsersPermissionsUser) ?? null,
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

const useApp: () => ContextProps = () => useContext(Context)

const httpLink = createHttpLink({
  uri: "/graphql",
})
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: localStorage.getItem("jwt") ? `Bearer ${localStorage.getItem("jwt")}` : "",
    },
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const AntConfigProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useBreakpoints()

  if (isMobile) {
    return (
      <ConfigProvider theme={theme}>
        <ConfigProvider theme={mobileTheme}>{children}</ConfigProvider>
      </ConfigProvider>
    )
  }

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>
}

const App: FC = memo(() => {
  return (
    <ApolloProvider client={client}>
      <ContactContextProvider>
        <JobsContextProvider>
          <InvoiceContextProvider>
            <AntApp>
              <ContextProvider
                app={app}
                user={defaultValue.user}
                company={defaultValue.company}
                members={defaultValue.members}
              >
                <AntConfigProvider>
                  <NotificationProvider>
                    <Suspense fallback={ <Loader spinning />}>
                      <Router />
                    </Suspense>
                  </NotificationProvider>
                </AntConfigProvider>
              </ContextProvider>
            </AntApp>
          </InvoiceContextProvider>
        </JobsContextProvider>
      </ContactContextProvider>
    </ApolloProvider>
  )
})

export { useApp }

export default App
