import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import localeData from "dayjs/plugin/localeData"
import weekOfYear from "dayjs/plugin/weekOfYear"
import weekYear from "dayjs/plugin/weekYear"
import weekday from "dayjs/plugin/weekday"
import { createRoot } from "react-dom/client"
import App from "./app"
import markerSDK from "@marker.io/browser"

await markerSDK.loadWidget({
  project: import.meta.env.VITE_MARKER_ID,
})

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const rootElement = document.querySelector("#root") as HTMLElement

createRoot(rootElement).render(<App />)
