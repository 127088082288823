import { Layout as BaseLayout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"
import { DefaultHeader } from "./ui/DeafaultHeader"
import { DefaultFooter } from "./ui/DefaultFooter"
import { HeaderMenu } from "./ui/HeaderMenu"
import { Loader } from "../../ui/Loader"

export const DefaultLayout: FC = () => (
  <BaseLayout>
    <BaseLayout.Header>
      <DefaultHeader renderMenu={HeaderMenu} />
    </BaseLayout.Header>
    <BaseLayout>
      <Suspense fallback={<Loader size={"large"} style={{ marginTop: "100px", marginBottom: '600px' }} spinning />}>
        <Outlet />
      </Suspense>
    </BaseLayout>
    <BaseLayout.Footer style={{ paddingBottom: "0", paddingTop: "0", backgroundColor: "#12212B" }}>
      <DefaultFooter />
    </BaseLayout.Footer>
  </BaseLayout>
)
